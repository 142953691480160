function convertTelegramTags() {
   const textNodes = []
   const walk = document.createTreeWalker(
     document.body,
     NodeFilter.SHOW_TEXT,
     null,
     false
   )

   while (walk.nextNode()) {
     textNodes.push(walk.currentNode)
   }

   const regex = /@([a-zA-Z0-9_]+)/g

   textNodes.forEach(node => {
     let text = node.nodeValue
     let newText = text.replace(regex, (match, username) => {
       return `<a href="https://t.me/${username}" target="_blank">${match}</a>`
     })

     if (newText !== text) {
       const span = document.createElement('span')
       span.innerHTML = newText
       node.parentNode.replaceChild(span, node)
     }
   })
 }
window.addEventListener('load', convertTelegramTags)